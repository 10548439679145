import axios from "axios";
import _ from "lodash";
import { nanoid } from "nanoid";
import { getDatabase, ref, set } from "firebase/database";
import {
  setDoc,
  query,
  onSnapshot,
  where,
  deleteDoc,
  QuerySnapshot,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { useStoreId } from "../../core/composables/useStoreId";
import PrinterService from "../../core/services/PrinterService";
import dataservice from "../../core/services/dataservice";
import {
  useFirebase,
  useFirebaseFirestore,
} from "../../core/composables/useFirebase";

export const useProductsStore = defineStore("products", {
  state: () => {
    return {
      products: null as any,
      productsByKey: {},
      productAvailableHolder: {} as { [productId: string]: number },
      nonce: 0,

      isLoading: false,      
      loading: 0, //0 completed -> 1 modify database -> 2 start reload(fetch) -> 3 fetihng

      //--temp for batch insert
      group: null,
      groupId: null,
      groupName: null,
    };
  },
  getters: {
    // Product
    getProductById: (state) => {
      return (productId: string) =>
        state.products.find((product: any) => product.productId === productId);
    },
    getProductNameById: (state) => {
      return (productId: string) => {
        const product = state.productsByKey[productId];
        return product ? product.name : "unknown";
      };
    },
    getProductsByParentId: (state) => {
      return (id: string) =>
        _.find(state.products, (product: any) => product.parentId === id);
    },
    getProductsById: (state) => {
      return (id: string) =>
        _.find(state.products, (product: any) => product.productId === id);
    },

    // COMPUTED
    getProducts() {
      // new

      let types = _.filter(this.products, (item) => item.level === 0);
      types = sortRow(types);

      //each type find groups
      let products: any[] = [];
      _.forEach(types, (type) => {
        let groups = _.filter(
          this.products,
          (item) => item.level === 1 && item.parentId === type.productId
        );
        groups = sortRow(groups);

        //each group find items

        const groupList: any[] = [];

        _.forEach(groups, (group) => {
          let items = _.filter(
            this.products,
            (item) => item.level === 2 && item.parentId === group.productId
          );
          items = sortRow(items);
          groupList.push(_.assign(group, { items }));
        });

        products.push(_.assign(type, { groups: groupList }));
      });

      // console.log(products);
      return products;
    },

    getProductFromLevel1() {
      // new

      let groups = _.filter(this.products, (item) => item.level === 1);
      groups = sortRow(groups);

      //each type find groups
      let products: any[] = [];
      _.forEach(groups, (group) => {
        let items = _.filter(
          this.products,
          (item) => item.level === 2 && item.parentId === group.productId
        );
        items = sortRow(items);
        products.push({
          ...group,
          items: items,
        });
      });

      // console.log(products);
      return products;
    },

    getProductGroupsByItemHolder: (state) => {
      //Make Group Holder [groupId]:{}
      let groupHolder = {};

      _.forEach(state.products, (item) => {
        if (item.level !== 1) return;

        if (groupHolder[item.productId] === undefined) {
          groupHolder[item.productId] = {};
        }

        let newGroup = { ...item };
        delete newGroup.items;
        groupHolder[item.productId] = newGroup;
      });

      //Make Item Holder [itemId]:groupData
      let products = {};

      _.forEach(state.products, (item) => {
        if (item.level !== 2) return;
        products[item.productId] = groupHolder[item.parentId];
      });

      // console.log(products);
      return () => products;
    },

    getProductsForSearch() {
      const groups = _.filter(this.products, (item) => item.level === 1);
      const products: any[] = [];
      _.forEach(groups, (group) => {
        const items = this.getProductsByParentId(group.productId);
        _.forEach(items, (item) => {
          products.push(_.assign(item, { category: group.name }));
        });
      });

      return products;
    },
    randomProduct(state) {
      return (ts: any) => {
        let level2 = _.filter(state.products, (item) => item.level === 2);
        const random = Math.floor(Math.random() * level2.length);
        return level2[random];
      };
    },

    // product - options
    getOptionsList: (state) => {
      let list = [] as any;
      list = _.filter(state.products, (item) => item.isOption);
      return list;
    },

    getProductAvailableHolder: (state) => {
      return state.productAvailableHolder;
    },
  },
  actions: {
    async main() {
      await this.fetch();
      await this.fetchAvailableProduct();
      await  this.delayFetchWhenUpdate();
      await  this.blockScreenSteps();
    },
    delayFetchWhenUpdate() {
      return;
      const waitFetch = _.debounce(this.fetch, 1000);
      let cacheNonce = 0;
      this.$subscribe((mutation, state) => {
        //if previousJobsRef changed
        if (cacheNonce != state.nonce) {
          cacheNonce = state.nonce;
          waitFetch();
        }
      });
    },

    blockScreenSteps() {
      //watch loaing change data
      let running = false;
      this.$subscribe(async (mutation, state) => {
        if (running == false && state.loading == 2) {
          running = true;
          await this.fetch();
          await this.syncToFirebase();
          this.loading = 0;
          running = false;
          this.nonce = nanoid();

          console.log("reload and sync done", new Date().toString());
        }
      });
    },

    async fetch() {  
      return new Promise(async (resolve, reject) => {
        const res = await dataservice.products.getAll();

        this.products = res.data;
        const productsByKey: any = {};
        _.forEach(this.products, (product) => {
          productsByKey[product.productId] = product;
        });
        this.productsByKey = productsByKey;

        // loop to get printers
        let printerMap = {} as any;
        _.forEach(this.products, (product) => {
          if (product.printer) {
            printerMap[product.productId] = product.printer;
          }
        });
        new PrinterService().setItemsPrinter(printerMap);   
        resolve(true);
      });
    },

    async insert(product: any) {
      this.loading = 1;
      let newProduct = {
        ...product,
        storeId: useStoreId(),
      };
      const res = await dataservice.products.post(newProduct);
      this.nonce = nanoid();

      this.loading = 2;
    },
    async insertBatch(productList: any, callback: any) {
      // loop post to server
      this.loading = 1;
      const promiseList = productList.map((product: any) => {
        return dataservice.products.post({
          ...product,
          storeId: useStoreId(),
        });
      });
      const res = await Promise.all(promiseList);
      this.nonce = nanoid();
      this.loading = 2;
    },
    async update(product: any, isSync: boolean = true) {
      if (isSync) this.loading = 1;

      let newProduct = {
        ...product,
        storeId: useStoreId(),
      };
      const res = await dataservice.products.put(newProduct);
      if (isSync) {
        this.nonce = nanoid();
        this.loading = 2;
      }
    },

    async updateBatch(productList: any) {
      this.loading = 1;
      const promiseList = productList.map((product: any) => {
        return dataservice.products.put({
          ...product,
          storeId: useStoreId(),
        });
      });
      const res = await Promise.all(promiseList);
      this.nonce = nanoid();
      this.loading = 2;
    },
    async delete(product: any) {
      this.loading = 1;

      let newProduct = {
        ...product,
        storeId: useStoreId(),
      };
      const res = await dataservice.products.delete(newProduct);
      this.nonce = nanoid();
      this.loading = 2;
    },
    async updateRow(newProductList: any) {
      this.loading = 1;
      const productListChanged = this.compareRows(newProductList);
      const promiseList = productListChanged.map((product: any) => {
        return dataservice.products.put({
          productId: product.productId,
          storeId: product.storeId,
          row: product.row,
        });
      });

      await axios.all(promiseList);
      this.loading = 2;
      this.nonce = nanoid();
    },

    async updateImage(product: any, key, url) {
      this.loading = 1;

      let newProduct = {
        image: {
          key,
          url,
        },
        productId: product.productId,
        storeId: useStoreId(),
      };
      const res = await dataservice.products.put(newProduct);
      this.nonce = nanoid();
      this.loading = 2;
    },
    compareRows(newProductList: any) {
      const productRowChanged = [] as any;
      _.forEach(newProductList, (product, productIdx) => {
        if (product.row !== productIdx) {
          product.row = productIdx;
          productRowChanged.push(product);
        }
      });
      return productRowChanged;
    },

    // ----
    syncToFirebase() {
      let productsRef = useFirebase().productsRef();

      let payload = {
        list: this.products,
        computed: this.getProductFromLevel1,
        hash: nanoid(8),
        lastUpdate: new Date().toString(),
      };

      set(productsRef, payload);
    },

    async fetchAvailableProduct() {
      let { subCollectionRef } = useFirebaseFirestore();
      let ordersRef = subCollectionRef("appState");
      onSnapshot(ordersRef, (querySnapshot: QuerySnapshot) => {
        querySnapshot.docs.forEach(async (doc) => {
          this.productAvailableHolder = doc.data();
        });
      });
    },
    setSoldOutProduct(data) {
      let { soldoutProductCollectionRef } = useFirebaseFirestore();
      const docRef = soldoutProductCollectionRef();
      return setDoc(docRef, data, { merge: true });
    },
    deleteSoldOutProduct(productId) {
      let { soldoutProductCollectionRef } = useFirebaseFirestore();
      const docRef = soldoutProductCollectionRef();
      return updateDoc(docRef, { [productId]: deleteField() });
    },
  },
});

//sort by row
function sortRow(list) {
  return list.sort((a, b) => a.row - b.row);
}
